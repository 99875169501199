const codeInputRow = document.querySelectorAll('.code-input__row');
codeInputRow.forEach((el) => {
  const inputs = el.querySelectorAll('.input-field__input');

  inputs.forEach((input, index) => {
    const parentElem = input.closest('.input-field');

    if (index < inputs.length - 1) {
      const nextSibling = parentElem.nextElementSibling;
      const nextSiblingInput = nextSibling.querySelector('.input-field__input');
      input.addEventListener('keyup', (event) => {
        if (event.keyCode === 13 || input.value.length === 1) {
          nextSiblingInput.focus();
        }
      });
    }

    if (index > 0) {
      const prevSibling = parentElem.previousElementSibling;
      const prevSiblingInput = prevSibling.querySelector('.input-field__input');
      input.addEventListener('keydown', (event) => {
        if (event.keyCode === 8 && input.value.length === 0) {
          prevSiblingInput.focus();
        }
      });
    }
  });
});

attachEvent('.code-input__button', 'click', (ev) => {
  const parentElem = ev.target.closest('.code-input');
  const addCodeButton = parentElem.querySelector('.code-input__button');
  const allRows = parentElem.querySelectorAll('.code-input__row');
  const lastActiveRow = parentElem.querySelectorAll('.code-input__row.is-active');
  const nextActiveRow = allRows[lastActiveRow.length];
  nextActiveRow.classList.add('is-active');
  if (allRows.length === lastActiveRow.length + 1) {
    addCodeButton.classList.add('hide');
  }
});
